.how_to_play {
  background: #12332E;
  height: 150px;
  color: #ffffff;
  display: flex;
  align-items: center;
  padding: 0 45px;
}
.lbl_how_to_play {
  font-size: 35px;
  font-weight: 700;
  color: #ffffff;
  font-family: "Roboto Condensed";
  font-style: italic;
}
.how_to_play .my_how_to_play_center {
  max-width: 1440px;
  margin: 0 auto;
  padding: 10px 20px;
}
.how_to_play .how_to_play_steps {
  justify-content: center;
  display: flex;
  font-family: "Roboto Condensed";
  font-style: normal;
}
.how_to_play_container {
  margin-top: 45px;
  padding: 55px;
  width: auto;
  height: auto;
  left: 0px;
  background: #f3f3f3;
}
.how_to_play_container .how_to_play_step {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 25px;
  color: #12332e;
}
.how_to_play_video {
  width: 50%;
  display: flex;
  margin: 20px auto;
  height: 200px;
}

@media (max-width: 991px) {
  .how_to_play_container {
    padding: 25px;
  }
  .page_content_center {
    padding: 0;
  }
  .how_to_play_video {
    width: 100%;
    display: flex;
    margin: 20px auto;
    height: 200px;
  }
}

@media (min-width: 992px) {
  .how_to_play_container {
    padding: 80px;
  }
  .how_to_play_video {
    width: 100%;
    display: flex;
    margin: 20px auto;
    height: 490px;
  }
  .safer_gambling_container,
  .feedback_container {
    padding: 80px 0;
  }
  .deposit_limit.safer_gambling_container.mt-3 {
    padding-bottom: 0;
  }
}
