.register_container{ width: 100%;  background: #E0E0E0; padding: 2.5rem 3rem;}
.active_account_container{ width: 100%;  background: #E0E0E0; padding: 2.5rem 3rem;}
.register_input{border: 1px solid #12332E; color:#6F6F6F; border-radius: 6px; height: 45px; width: 100%; padding: 0 14px; outline: none;}
.register_container .btn_login{color: #539384; text-decoration: none; margin-left: 15px; font-weight: 600;}
.register_checkbox{width: 30px; height: 30px; border-radius: 6px; margin-right: 15px;}
.btn_already_have_acc{text-decoration: none; color: #12332E; font-weight: bold; margin-left: 10px;}
.already_have_acc_text{color: #12332E; font-weight: 400;}
.register_terms{color: #12332E; font-weight: 300;}
.btn_active_account{
    float: right;
    margin-top: 1rem !important;
}
@media (min-width : 992px){
    .register_now_container{justify-content: flex-end;}
    .mt-row{margin-top: 1.5rem;}
    .btn_active_account{
        margin-top: 0px ;

    }
}

@media (max-width : 991px){
    .register_now_container{justify-content: center;  margin-top: 1.5rem; }
    .mt-row{margin-top: 0;}
    .btn_active_account{margin-top: 1rem;}
    .active_account_container{ width: 100%;  background: #E0E0E0; padding: 5.5rem 3rem;}


}