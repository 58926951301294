.banner-single-comp {
    /* margin-top:30px; */
    margin-bottom:20px;
    padding: 17px;


    /* height: 100px; */
    border:1px solid #12332E;

    background-repeat: no-repeat;
    /* background-attachment: fixed; */

    cursor: pointer;
    border-radius: 5px;

    /* text-shadow: 0 2px 3px #fff; */

    color:#12332E;


    /* background-image: url('../../../public/assets/image/banner_distort_3.png'); */

    /* https://www.magicpattern.design/tools/css-backgrounds */
    background-color: #fff;
    background-image:  linear-gradient(135deg, #12332E10 25%, transparent 25%), linear-gradient(225deg, #12332E10 25%, transparent 25%), linear-gradient(45deg, #12332E10 25%, transparent 25%), linear-gradient(315deg, #12332E10 25%, #fff 25%);
    background-position:  10px 0, 10px 0, 0 0, 0 0;
    background-size: 20px 20px;
    background-repeat: repeat;
    font-weight: bold;
}


.banner-single-comp .banner-content h3 {
    font-weight: bold;
}

@media (min-width: 992px) {
    .banner-single-comp {

        /* text-shadow:none; */
        background-color: #12332E;
        color:#fff;
        background-size:auto;
        background-repeat:no-repeat;
        background-position: bottom right;
        background-image: url('../../../public/assets/image/banner_distort_3.png');
        font-weight: normal;
    }
}

.banner-single-comp .signup-text {color: #d99d0b}

.my_competitions_container{background: #F3F3F3; padding: 55px; font-size: 15px;}
.competitions_titles{ background: #FFFFFF;   margin: 10px 0;  background: #FFFFFF;  height: auto; padding: 12px 0; display: flex;align-items: center; color: #12332E;  font-family: 'Roboto Condensed';font-weight: 700; border-radius: 6px;}
.public_icon, .private_icon{display: inline-flex; width: auto; color: #12332E; margin-right: 20px;}
.public_icon img, .private_icon img{width: 20px; height: 20px; margin-right: 5px;}
.gamecard_icon{display: inline-block; margin: 0 8px; cursor: pointer;}
.gamecard_icon img{width: 25px; height: 25px;}
.leaderboard_icon{display: inline-block; cursor: pointer; margin: 0 8px;}
.leaderboard_icon img{width: 25px; height: 25px;}
.competition.row { background: #FFFFFF; margin-bottom: 0; height: auto; padding: 12px 0; display: flex; align-items: center;  color: #12332E; font-family: 'Roboto Condensed';}
.leaderboard_header span{display: inline-block; width: auto; color: #12332E; font-size: 20px; font-family: 'Roboto'; font-style: normal; font-weight: bold; padding: 0;}
.leaderboard_header .leaderboard_icon{margin-left: 20px;}
.leaderboard_row{background: #FFFFFF; border-radius: 6px; margin:0;}
.leaderboard_info{color:#12332E; font-weight: bold;}
.leaderboard_status{color: #12332E;}
.leaderboard_table .row div{margin: 8px 0;}
.leaderbaord_league_name{margin-left: 15px;}
.gamecard_title{color: #12332E; font-size: 20px; font-weight: bold; margin-right: 15px; display: inline-block; vertical-align: middle; font-family: 'Roboto';}
.free_to_play_title{color: #12332E; font-size: 20px; font-weight: bold; display: inline-block; vertical-align: middle;}
.gamecard_competition{display: block; margin-top: 25px;}
.gamecard_positions{margin-top: 25px; width: 80%;}
.triple_points, .double_points{border-radius: 20px; box-sizing: border-box; display: inline-flex; align-items: center; justify-content: center; color: #539384; font-weight: bold; font-style: italic;}
.points_info, .gamecard_howtoplay_info{width: 25px; height: 25px; border-radius: 50%; background: #539384; display: flex; align-items: center; justify-content: center; color: #fff; font-weight: bold;}
.triple_points_icon{height: 35px; width: 35px; margin-right: 15px;}
.double_points_icon{height: 30px; width: 30px; margin-right: 15px;}
.competitions_gamecard_leader_i, .competitions_public_private_i{width: fit-content;}
.leaderboard_container{background: #F3F3F3; padding: 55px;}

/* GameCard component style */
.gamecard_container{background: #F3F3F3; padding: 55px; padding-bottom: 0;}
.gamecard_odds_types{background: #fff; height: auto; align-items: center; border-radius: 6px; color: #12332E; font-weight: bold; padding: 8px 0; margin-top: 20px;}
.gamecard_odds_row{background: #fff; border-radius: 6px; color: #12332E; padding: 8px 15px;}
.game_info{     color: #E0E0E0; background: #12332E;  width: 20px;  height: 20px;  display: inline-flex;  border-radius: 50%; justify-content: center; align-items: center;}
.hometeam, .awayteam{  display: flex;  align-items: center; justify-content: space-between; padding: 2px 5px;}
.hometeam_name, .awayteam_name { color: #12332E; font-weight: bold; text-transform: uppercase; display: inline-flex; width: 65%; word-break: break-word;}
.double_triple_points span{ display: inline-flex; width: 25px; height: 25px; margin-left: 15px;}
.gamecard_container .double_triple_points span{cursor: pointer;}
.gamecard_container .clickable{cursor: pointer;}
.home.active, .away.active, .draw_odds.active{background: #12332E !important; color: #FFFFFF; font-family: 'Roboto'; font-style: normal;font-weight: bold;}
.gamecard_container .double_game_points{margin-left: 22px;}
.gamecard_container .gamecard_points.change_points{opacity: 0.5;}
.gamecard_container .change_points.active{opacity: 1;}
.gamecard_points.active{opacity: 1 !important;}
.gamecard_container .gamecard_points {opacity: 0.2;}
.points_cards_succes{background: transparent; border: none;}
.completed_badge{padding: 1px; border: 1px solid; border-radius: 6px; background: #EAD840;}
.completed_badge_live{padding: 1px; border: 1px solid; border-radius: 6px; background: #EAD840; width:70px}
.draw_odds.clickable{flex-direction: row;}
.home{flex-direction: row;}
.away{flex-direction: row;}
.match_tick{margin-left: 5px;}
.how_to_play_wildcards{ width: 100%;display: flex;margin: 20px auto;height: 200px;}
.how_to_play_wildcards_video{ width: 100%;display: flex;height: 200px;}
.game_info, .points_info{cursor: pointer;}
#modal_match_details.modal-dialog{max-width: 750px;}
#modal_match_details .modal-title{color: #12332E; font-family: 'Roboto'; font-size: 17px; font-weight: bold; text-transform: uppercase;}
.nav-tabs{display: flex; justify-content: center;}
.nav-tabs .nav-link {color: #12332E !important; background: #F3F3F3; margin: 0 5px; margin-bottom: 10px; border-radius: 6px; border: none;}
.nav-tabs .nav-link.active{background: #12332E; color: #F3F3F3 !important;}
.match_result{border: 1px solid #12332E; box-sizing: border-box;  border-radius: 5px; position: relative;}
.match_result .home{border-bottom: 1px solid #12332E;}
.match_result .home, .match_result .away{padding: 2px 5px;}
.match_details_result{position: absolute; right: 5px; color: #12332E; font-family: 'Roboto'; font-weight: bold;}
.active_tab{border-bottom: 3px solid #D99D0B; width: auto; height: auto; padding: 1px;}
.gamecard_tab.active_tab{margin-right: 20px;}
.leaderboard_tab.active_tab{margin-left: 20px;}
.gamecard_market_name{text-align: center;}
.gamecardrow{font-size: 14px;}
.hours_countdown{padding: 2px}
.row.transaction{border-radius: 10px;}
.price_container img{width: 35px; height: 45px;}
.price_container .price{width: auto; height: auto; display: inline; background: #12332E; color: white; border-radius: 5px; padding: 1px 5px;}

@media(max-width:991px){
    .btn_submit_golf_predictions{justify-content: center !important;}
    .alignText{text-align: left;}
    .competitions_titles{display: none;}
    .my_comps_btns{display: flex; text-align: center;}
    .my_comps_btns button{margin: 20px auto;}
    .my_comps_btns .btn_secondary.btn_live_competitions{width: 135px;}
    .my_comps_btns .btn_secondary.btn_completed_competitions{width: 135px;}
    .my_competitions_container .private_icon{margin-top: 0;}
    .my_competitions_container .competition_icons{display: none !important;}
    .competition_icons_mobile{margin-top: 12px; margin-bottom: 35px;}
    .competitions_icons_row{margin:0; margin-bottom: 25px;}
    .competitions_public_private_col_mobile .public_icon{margin: 0;}
    .competitions_public_private_col{display: none;}
    .competition_title { display: none;}
    .my_competitions_container{padding: 20px;}
    .leaderboard_status, .leaderboard_info{height: auto;}
    .leaderboard_status div{display: flex; align-items: center; justify-content: center; margin: 8px 0;}
    .gamecard_info{display: block;}
    .gamecard_free_to_play{margin: 25px 0;}
    .points_container{display: flex; text-align: center; justify-content: space-between; margin-top: 40px !important; flex-direction: column-reverse !important;}
    .points_container div{margin: 5px 0; padding: 0;}
    .triple_points, .double_points{font-size: 14px;}
    .gamecard_howtoplay_info{margin-left: 8px;}
    .points_info{margin: 0 auto !important;}
    .public_icon, .private_icon{display: block;}
    .competitions_gamecard_leader_i span, .competitions_public_private_i span{display: block; margin: 12px 0;}
    .competitions_gamecard_leader_i span img{margin-right: 5px;}
    .gamecard_icon, .leaderboard_icon{margin: 0 25px;}
    .gamecard_odds_types{display: none;}
    /* .golf_container{display: none;} */
    .odds_col{display: none;}
    .gamecard_odds_row{ border: 1px solid #12332E;}
    .game_time{padding: 0; padding-left: 10px; border-bottom: 1px solid #12332E;}
    .gamecard_odds_row{padding: 0;}
    .teams_col{height: auto;}
    .teams{width: 100%; height: 100%; display: flex;  align-items: center;}
    .hometeam { display: inline-flex;   position: relative;  width: 50%; height: 100%; justify-content: flex-end; padding: 10px; font-size: 13px; font-weight: 900;}
    .awayteam { display: inline-flex;   position: relative;  width: 50%; height: 100%; justify-content: flex-start;  padding: 10px; font-size: 13px; font-weight: 900;}
    .hometeam .hometeam_name{max-width: 100px; width: 100%; text-align: end; display: inline-block;overflow: hidden;text-overflow: ellipsis;}
    .awayteam .awayteam_name{max-width: 100px; width: 100%; text-align: start; display: inline-block;overflow: hidden;text-overflow: ellipsis;}
    .hometeam .game_info{position: absolute; left: 5px; margin-top: auto; margin-bottom: auto; bottom: 0; top: 0;}
    .match_home_score{ left: 0;  margin-top: auto; margin-bottom: auto; bottom: 0; top: 0;  height: 20px; display: inline-flex;}
    .match_away_score{ right: 0;  margin-top: auto; margin-bottom: auto; bottom: 0; top: 0;  height: 20px; display: inline-flex;}
    .awayteam .game_info{position: absolute; right: 5px; margin-top: auto; margin-bottom: auto; bottom: 0; top: 0;}
    /* .hometeam .game_info{position: absolute; left: 5px;} */
    .gamecard_container{padding: 25px;}
    .leaderboard_container{padding: 25px;}
    .game_info{position: absolute; right: 0;}
    .game_odds{display: none; background: #fff; border-radius:0 0 10px 10px; height: auto; padding: 20px 0 20px 0; box-shadow: 0px 2px 4px rgb(0 0 0 / 25%);}
    .active_game_odds{display: block;}
    .game_odd_section{background: #FFFFFF; border: 1px solid #12332E; border-radius: 5px; text-align: center; width: 70px; margin: 0 auto;}
    .team_name{cursor: pointer;}
    .private_competition_i{display: block; margin-bottom: 30px;}
    .private_competition_title{display: block; font-weight: bold; margin:10px 0;}
    .my_competition_info{display: none;}
    .private_competition_icons{margin: 30px 0; display: flex; justify-content: center; display: flex;}
    .private_copy_share_row{display: none;}
    .competitions_public_private_col, .competitions_entry_fee, .competitions_entries, .competitions_positions{width:100%; margin: 10px 0;}
    .match_datetime_status{ display: flex; justify-content: center;  flex-direction: row;   padding: 3px 0; align-items: center;}
    .competition_position{width: 100%; margin: 10px 0;}
    .competition_col{margin: 10px 0;}
    .gamecard_title{font-size: 14.5px; margin-right: 5px;}
    .leaderboard_header span{font-size: 14.5px;}
    .leaderboard_header .leaderboard_icon{margin-left: 5px;}
    .gamecard_positions{margin-top: 15px; width: 100%;}
    .gamecard_positions span{margin: 3px 0;}
    .triple_points, .double_points{ border: transparent; background: transparent;}
    .triple_points .triple_points_icon{height: 25px; width: 25px; margin-right: 5px;}
    .double_points .double_points_icon{height: 20px; width: 20px; margin-right: 5px;}
    .points_info{height: 20px; width: 20px;}
    .match_datetime_status{background: #12332E; color: #FFFFFF; }
    .vs{color: #12332E; font-family: 'Roboto'; font-style: normal; font-weight: bold;}
    .hometeam{padding-right: 15px;}
    .awayteam{padding-left: 15px;}
    .home.active, .away.active, .draw_odds.active{background: #12332E !important; color: #FFFFFF; font-weight: 900;}
    .game_info{width: 18px; height: 18px;}
    .completed_badge{background: #D99D0B; text-transform: uppercase; border: none;  border-radius: 10px;   padding: 0 8px;   position: absolute;
        right: 5px; font-size: 12px;  font-style: italic;font-weight: 500; font-family: 'Roboto Condensed'; margin: 0 !important; letter-spacing: 0.05em;}
    .match_datetime_status.game_time{position: relative;}
    .match_home_score_mobile, .match_away_score_mobile{ width: 15px;  height: 14px; background: #12332E; color: #ffffff;  display: flex;  align-items: center; justify-content: center;  margin: 0 1px; padding: 10px;}
    .game_odd_section{position: relative; display: flex; justify-content: center; align-items: center;}
    .match_tick{position: absolute; right: 4px; height: 15px; width: 15px;}
    .gamecard_odds_row{border: none;}
    .match_datetime_status{border-radius: 10px 10px 0 0;}
    .teams_col{box-sizing: border-box; border-radius: 0 0 10px 10px; border: 1px solid #12332E;}
    .match_away_score, .match_home_score{display: none;}
    .gamecard_submit_row{ background: #12332E; padding:25px; justify-content: center;}
    /* .gamecard_container{ overflow-y: scroll; height: 100%; z-index: 1;} */
    .gamecard_submit_row{z-index: 2;}
    .teams_col.active_team_col{border-radius:  0 0 0 0;}
    .game_odds{box-sizing: border-box; border: 1px solid #12332E; border-top: 0;}
    .points_container{max-width: 450px;}
    .gamecard_odds_container{max-width: 450px;}
    .gamecard_container{display: flex;}
    .submit_container{display: flex; justify-content: center;}
}

@media(min-width:992px){
    .alignText{text-align: right;}
    .completed_badge{background: #D99D0B; color: #FFFFFF; text-transform: uppercase; border: none;  border-radius: 10px;  padding: 0 8px; font-size: 12px; font-style: italic; font-weight: 500; font-family: 'Roboto Condensed'; margin: 0 !important;  letter-spacing: 0.05em;}
    .triple_points, .double_points{width: fit-content;  padding: 10px 15px;}
    .row.competition:not(.titles) { border-radius: 0; }
    .competitions_titles{display: flex;}
    .my_comps_btns{display: flex; justify-content: center;}
    .leaderboard_status, .leaderboard_info{height: 45px;}
    .leaderboard_remaining_matches{display: flex; justify-content: flex-end;}
    .leaderboard_league{display: flex; justify-content: center;}
    .gamecard_free_to_play{display: flex; flex-direction: column; align-items: flex-end; text-align: end;}
    .competitions_gamecard_leader_i{display: none;}
    .teams_col{justify-content: space-between;}
    .teams {width: 100%; border: 1px solid #12332E;  border-radius: 5px; }
    .hometeam{border-bottom: 1px solid #12332E;}
    .gamecard_submit_row{justify-content: flex-end;}
    .win_odds{ width: 65px; height: 50px; display: flex; flex-direction: column; align-items: center;  justify-content: center;  border-radius: 5px; }
    .win_odds .home, .win_odds .away{width: 100%; text-align: center;}
    .win_odds .home{border-radius: 5px 5px 0 0; width: 100%; position: relative; height: 100%;   box-sizing: border-box; border: 1px solid #12332E;}
    .win_odds .away{border-radius: 0 0 5px 5px; width: 100%; position: relative;  height: 100%;  box-sizing: border-box; border: 1px solid #12332E; border-top: 0;}
    .draw_odds{ width: 65px;  height: 50px; position: relative;  display: flex;  flex-direction: column; align-items: center; border: 1px solid #12332E;justify-content: center;  border-radius: 5px; }
    .game_odds{display: none;}
    .private_competition_title{display: none;}
    .private_competition_icons.share_copy{display: none;}
    .sport_col{display: flex; justify-content: center;}
    .competitions_public_private_col{width: 30px;}
    .competitions_entry_fee{width: 120px;}
    .competitions_entries{width: 55px;}
    .competitions_positions{width: 120px;}
    .my_competitions_container .transaction.row .col{margin: 0;}
    .odds_col{display: flex; justify-content: center; flex-direction: column;}
    .match_away_score, .match_home_score{margin-right: 5px;}
    .match_score_info{display: flex; width: 35%; justify-content: flex-end;}
    .match_datetime_status{ display: flex;  justify-content: center;  flex-direction: column; align-items: center; padding: 0;}
    .competition_position{width: 75px;}
    .competition_icons{margin-right: 10px;}
    .competition_tab{width: fit-content;}
    .competition_width{width: 105px;}
    .competitions_public_private_col_mobile{display: none;}
    .competition_icons_mobile{display: none !important;}
    .vs{display: none;}
    .match_home_score_mobile, .match_away_score_mobile{display: none;}
    .gamecard_submit_row{ background: #F3F3F3; padding:0 55px;}
    .points_info{margin: 10px 15px;}
    /* .match_tick{position: absolute; right: 4px;} */
    .submit_container{max-width: 1200px;  margin: 0 auto; display: flex; justify-content: flex-end; margin: 0 auto; padding: 30px 55px;}
}

@media screen and (min-width: 992px) and (max-width: 1305px) {
    .cricket_odds{max-width: 70px; padding: 0 0.5px;}
    .gamecard_odds_row{padding: 8px 0;}
    .match_markets_names{justify-content: center;}
}

.wizard-steps {
    width: 100%;
    display: flex;
    justify-content: space-between;
    font-size: 14px;
}
.wizard-steps hr {
    height: 3px !important;
    width: 100%;
    background: #6F6F6F !important;
    opacity: 1 !important;
    margin: 20px 0 !important;
}
.wizard-steps hr.active {
    background: #539384 !important;
}
.wizard-steps-info {
    display: flex;
    justify-content: space-between;
}

.step-index {
    width: 45px;
    height: 45px;
    background: #6F6F6F;
    color: #6F6F6F;
    border-radius: 50%;
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    text-align: center;
    border: 2px solid #6F6F6F;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: var(--cursor_pointer) 11 4, auto;
    margin: 0 10px;
}
.step-index.active {
    background: #539384;
    border: 2px solid #539384;
    color: #ffffff;
    border: none;
}
.rounds .active { border-bottom: 3px solid #12332E; width: auto; height: auto; padding: 1px;}
.golf_select_info{ margin: 70px 0 0 10px; font-weight: 700; font-size: 24px;}
.golf_info{ margin: 20px 0 0 10px; font-weight: 400; font-size: 18px;}
.select_anchor{height: 50px; border-radius: 10px;}
.wizard_steps{ text-align: center; font-weight: 700; font-size: 17px; margin-top: 10px;}
.golf_wildcard{ width: 35px; height: 25px;}
.rounds{font-weight: bold;}
.rounds:hover{cursor: pointer;}
.gold_wizard_info{min-height: 150px;}
.form-select:disabled{background-color: #fff !important;}
.m_player_time{border-bottom: 1px solid #12332E; background-color: #fff; color: #12332E; text-align: center; border-radius: 10px 10px 0px 0px; min-height: 25px;}
.m_card_container{border : 1px solid #12332E; border-radius: 11px}
.m_player{ padding: 10px; border-bottom: 1px solid #12332E; text-align: center; position: relative;}
.player_icon{position: absolute;right: 16px; top: 20px;}
.golf_m_wildcard{padding: 20px;}
.m_round_points{padding: 10px; font-size: 18px;}
.btn_submit_golf_predictions{display: flex; justify-content: flex-end;}
.change_points{opacity: 0.5;}
.golf_leaderboard .col-2{width: 12.5% !important;}
.g_mb_container{background-color: #ffff; padding: 5px; border-radius: 6px;}
.g_mb_container .col-4{font-weight: bold;}
.stats-round{ border: 1px solid #12332E; border-radius: 6px; }
.hole-row{border-bottom: 1px solid #12332E; margin: 0;}
.golf-stats .result-info {width: auto; padding: 0; font-size: 13px; display: flex;align-items: center; margin-right: 50px;}
.golf-stats-player-name{font-size: 18px; font-weight: bold;}
.golf-stats-player-country{font-size: 16px; font-weight: bold;}
.m-golf-stats-container{ border: 1px solid #12332E; border-radius: 6px;}
.m-round{padding: 10px;}
.m-round.active{background-color: #12332E; color: white; border-radius: 5px 5px 0 0;}
.gamecard_odds_row.show_modal{background: #12332E; color: #fff;}
.m_card_container.show_modal{background: #12332E; color: #fff;}
.btn-scoreboard{background-color: #fff; color: #12332E; border: 1px solid #12332E; border-radius: 5px; font-size: 14px;}
.disabled_select { -moz-appearance:none !important; /* Firefox */ -webkit-appearance:none !important; /* Safari and Chrome */appearance:none !important;background: transparent;}
.fit-container .col-12{max-height: 25px !important; min-height: 25px !important;}

.cricket_content_center {
    max-width: 1200px !important;
    margin: 0 auto;
    padding-bottom: 30px;
}

span.home_team_title{
    font-size: 11pt;
    font-weight: bold;
}
span.draw_title{
    font-size: 11pt;
    font-weight: bold;
}
span.away_team_title{
    font-size: 11pt;
    font-weight: bold;
}
.home_progress{
    background-color: #12332E;
}
.away_progress{
    background-color: #ffb500;
}
.draw_progress{
    background-color: gray;
}

.home_percent{
    color: #12332E;
}
.away_percent{
    color: #ffb500;
}
.draw_percent{
    color: gray;
}

.substitute_team_name{
    font-weight: bold;
    font-size: 16px;
    color: #ffb500;
}

.substitute_title{
    font-weight: bold;
    font-size: 14px;
}

.substitute_container_header{
    color: #12332E;
    font-family: Roboto;
}
.substitute_container_header_content{
    text-align: center;
    font-weight: bold;
    font-size: 15px;
}

