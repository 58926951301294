/*RULE*/
.rules_container{
    padding: 55px;
    width: auto;
    height: auto;
    left: 0px;
    background: #F3F3F3;
}
.rules_title{    
    font-style: italic;
    font-weight: bold;
    text-align: center;
    color: #12332E;
}
.rules_content_tittle{
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 25px;
    color: #12332E;
    letter-spacing: 0.05em;
}
.rules_title h2{font-weight: bold; font-family: 'Roboto';}

@media(max-width: 991px){
    .rules_container{padding: 55px;}
}

@media(min-width: 992px){
    .rules_container{padding: 80px;}
}