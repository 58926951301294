.challenge .row { background: #FFFFFF; margin-bottom: 0; height: auto; padding: 12px 0; display: flex; align-items: center;  color: #12332E; font-family: 'Roboto';}
.challenges_titles{ background: #FFFFFF;   margin: 50px 0 20px 0; background: #FFFFFF;  height: auto; padding: 12px 0; display: flex;align-items: center; color: #12332E;  font-family: 'Roboto';font-weight: 700;}
.f-bold{font-family: 'Roboto'; font-weight: 401;}
.free_to_play_btns{text-align: end;}
.free_to_play_btns button{margin:15px 10px}
.free_to_play_center_text{display: flex; justify-content: center;}
.free_to_play_start_text{display: flex; justify-content: flex-start;}
.selected_league_icon img{width: 20px; height: 20px; cursor: pointer;}
.challenge.transaction.active{background: #ffb500; color: #ffffff;}
.challenge.transaction.active .public_icon, .challenge.transaction.active.selected_league_icon{color: #FFFFFF;}
.challenges_titles .col{ margin: 0 10px}
.btn-join{width: 6vw;}
.tournament_league_name{
    min-width: 30vh;
}
.competition_style{
    min-width: 20vh;
}
@media(min-width: 1400px){
    .btn-join{width: 5vw;}
}

@media(max-width: 991px){
    .btn-join{width: 15vw;}
    .challange_container{padding: 20px;}
    .sports_league_btns{margin-top: 0;}
    .challenges_titles{display: none !important;}
    .challenges_icons_row{margin-bottom: 25px; display: flex;}
    .challanges_icons_row .private_icon{margin-top: 0;}
    .free_to_play_btns{text-align: center;}
    .challanges_entries, .challanges_price_pool, .challanges_entry_fee{margin: 10px 0;}
}