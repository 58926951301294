/*Terms And Conditions*/
.terms_and_conditions_container{
    padding: 55px;
    width: auto;
    height: auto;
    left: 0px;
    background: #F3F3F3;
}
.terms_and_conditions_title{    
    font-style: italic;
    font-weight: bold;
    font-size: 55px;
    line-height: 65px;
    text-align: center;
    color: #12332E;
}
.terms_and_conditions_title h2{font-weight: bold; font-family: 'Roboto';}

.terms_and_conditions_content_tittle{
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 25px;
    color: #12332E;
    /* letter-spacing: 0.05em; */
}
.terms_and_conditions_content{ margin-top: 45px;}
.terms_and_conditions_content li{font-weight: 300;}
.li_title{margin: 15px 0;}
.terms_conditions_ul{margin-top: 15px; padding: 0 10px;}
.terms_conditions_ul li{list-style: disc; color: #12332E;}

@media(max-width : 991px){
    .terms_and_conditions_container{padding: 55px;}
}

@media(min-width : 992px){
    .terms_and_conditions_container{padding: 80px;}
}
