/*RULE*/
.footer_competiton_container{
    padding: 55px;
    width: auto;
    height: auto;
    left: 0px;
    background: #F3F3F3;
}
.footer_competition_title{    
    font-style: italic;
    font-weight: bold;
    text-align: center;
    color: #12332E;
}
.footer_competition_title{
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 25px;
    color: #12332E;
    letter-spacing: 0.05em;
}
.footer_competition_title h2{
    font-weight: bold; 
    font-family: 'Roboto';     
    text-align: center;
    color: #12332E;
    font-weight: bold;
    font-style: italic;
    font-family: 'Roboto';}

@media(max-width: 991px){
    .footer_competiton_container{padding: 55px;}
}

@media(min-width: 992px){
    .footer_competiton_container{padding: 80px;}
}