.forgot_password_container{ width: 100%;  background: #E0E0E0; padding: 3rem;}
.forgot_password_email{border: 1px solid #12332E; color:#6F6F6F; border-radius: 6px; height: 45px; width: 100%; padding: 0 14px; outline: none;}
.remember_your_password{font-size: 15px;line-height: 35px;text-align: center;letter-spacing: 0.05em; }
.forgot_password_container .btn_login{color: #539384; text-decoration: none; margin-left: 35px; font-weight: 600;}


@media (max-width: 991px){
    .reset_password_container{justify-content: center;}
}
@media (min-width : 992px){
    .reset_password_container{ justify-content: flex-end; }
}
