.user_menu{background: #12332E;box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.65);border-radius: 5px;padding: 12px;width: fit-content; height: auto; position: absolute; right: 55px; z-index: 4;}
.user_menu button{ background: transparent;display: block;font-style: italic; font-weight: 700;letter-spacing: 0.05em;font-size: 14px;}
.user_menu button:hover{ color: #539384;}
.btn_myprofile, .btn_myaccount, .btn_mycompetiton, .btn_safergabmling{color: #ffffff;  border: none;  padding: 5px 15px; width: 180px; text-align: start;}
.btn_logout{color: #ffffff; border: none; width: 180px; padding: 5px 15px; text-align: start;}
.user_menu_center{max-width: 1440px; width: 100%; margin: 0 auto;}

@media (max-width: 991px) {
  .user_menu{
    text-align: center;
    color: #fff;
    height: auto;
    padding: 2rem;
    font-size: 20px;
    z-index: 4;
    background: #12332E;
    box-shadow: none;
    border-radius: none;
    width: fit-content;
    position: absolute;
  }
}