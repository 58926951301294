.login_container{ width: 100%;  background: #E0E0E0;  padding: 3rem; position: relative;}
#input_username{border: 1px solid #12332E; color:#6F6F6F; border-radius: 6px; height: 45px; padding: 0 14px; outline: none;}
#input_password{border: 1px solid #12332E; color:#6F6F6F; border-radius: 6px; height: 45px; padding: 0 14px; outline: none;}
#input_remember_user{height: 30px; width: 30px; margin-right: 10px; border-radius: 6px;}
.remember_user_text{color: #12332E; font-size: 14px;}
.btn_forgot_password{color: #12332E;  text-decoration: none;  font-size: 14px;}
.btn_sign_up{color: #539384; text-decoration: none; font-weight: 600; font-size: 14px;}
/* .login_header_container{display: flex; align-items: center; justify-content: space-between; } */
.login_header_buttons a{text-decoration: none; color: #ffffff; font-style: italic; font-weight: 600; letter-spacing: 0.05em;}
/* .login_header_container .navbar-nav a.btn_how_to_play.active {color: #ffb500 !important;} */
.active_login_btn{width: 3px; height: 33px; background: #ffb500;}
.create_acc_link{color: #539384; font-weight: bold; text-decoration: none;}
.login_header_center{display: flex; justify-content: space-between;align-items: center; padding: 40px 55px; max-width: 1440px; margin: 0 auto;}
.login_header_container .navbar-nav a.active{color: #ffb500 !important;}
.login_header_container .btn_login{margin-left: 35px;}
#input_remember_user{background: #FFFFFF; border: 1px solid #12332E;  box-sizing: border-box; border-radius: 10px;  display: inline-block; margin-right: 10px; height: 20px; width: 20px;}
.remember_user_text{ color: #12332E; font-weight: 300; font-size: 14px;} 
.login_container input::placeholder{text-transform: capitalize;}
.register_container input::placeholder{text-transform: capitalize;}
.forgot_password_container input::placeholder{text-transform: capitalize;}

@media (min-width : 992px){
    .login_forgot_password_container{display: flex; justify-content: space-between; align-items: center;}
    .create_acc_container{justify-content: space-between; }
    .login_btn_container{justify-content: space-between;}
    .login_header_container .navbar.nav{  display: flex;  align-items: center;justify-content: space-between; max-width: 1440px; margin: 0 auto;padding: 40px 55px;}
    .login_header_container .navbar-nav{justify-content: flex-end;}
    .login_header_container .navbar-nav a{ color: #ffffff !important; font-style: italic;  font-weight: bold;letter-spacing: 0.05em;}
    .login_header_container .btn_login{margin-left: 35px;}
    .remember_user_container{display: flex; align-items: center;}
}

@media (max-width : 991px){
    .login_forgot_password_container{display: block;}
    .create_acc_container{ flex-direction: column;}
    .create_acc_container{ position: absolute; bottom: 10px;}
    .login_btn_container{justify-content: center;}
    .btn_login_user{margin-bottom: 1.5rem;}
    .login_header_container .navbar.nav{padding: 20px; padding-bottom: 40px; border-radius: 5px; }
    .login_header_container .navbar-nav{
        justify-content: center !important; padding: 25px; border-radius: 5px; box-shadow: 0px 8px 12px rgb(0 0 0 / 65%);  height: 125px;
    }
    .login_header_container .navbar-nav a{ font-style: italic; font-weight: bold; letter-spacing: 0.05em;}
    .login_header_center{padding: 0;}
    .login_header_container .btn_login{margin-left: 0;}
    .login_container .btn_forgot_password{margin-top: 20px; text-align: center; display: block;}
    .remember_user_container{display: flex; align-items: center; justify-content: center;}

}