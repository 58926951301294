.privacy_policy_container{width: 100%; padding: 55px;background: #F3F3F3; color : #12332E;}
.privacy_policy_header{color: #12332E; text-align: center; font-weight: 700; font-style: italic;}
.privacy_policy_header_text{color : #12332E; margin-top : 50px;}
.introduction{color : #12332E; margin-top : 50px; font-weight: 700;}
.list_title{color : #12332E; margin-top : 50px; font-weight: 700; margin-bottom: 0;}
.list_titles{color : #12332E; margin-top : 50px;}
.list_container li{margin-bottom: 10px;}
.privacy_policy_container h2{font-weight: bold; font-family: 'Roboto';}


@media(max-width : 991px){
    .privacy_policy_container{padding: 55px;}
}

@media(min-width : 992px){
    .privacy_policy_container{padding: 80px;}
}

