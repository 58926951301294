.sports_center{
    justify-content: center;
}
.sports_content{
    width: 100%;
    align-items: center;
}
.nfl_img, .cricket_img, .international_img{
   max-width: 100%;
}
.sports_container_link{display: block;}
.sports_container_link img{width: 100%;}
@media (max-width: 991px){
    .sports_content_cricket .btn_play_now{background: #D99D0B; color: #ffffff; font-weight: bold; letter-spacing: 0.05em; border-radius: 10px; margin-top: 15px;}
    .sports_content_cricket .btn_secondary{
        transform: skew(-20deg);
        width: 90px;
        height: 20px;
    }
    .sports_content_cricket .btn_secondary span{ 
        font-size: small;
        display: flex;
        justify-content: center;
        transform: none;
    }
    .sports_content_nfl .btn_secondary{ 
        position: absolute;
        bottom: 30px;
        left: 21px;
        transform: skew(21deg);
        width: 90px;
        height: 20px;
    }
    .sports_content_nfl .btn_secondary span{ 
        font-size: small;
        display: flex;
        justify-content: center;
        transform: none;
    }
    .sports_content_international .btn_secondary{ 
        position: absolute;
        bottom: 8px;
        right: 23px;
        transform: skew(-20deg);
        width: 90px;
        height: 20px;
    }
    .sports_content_international .btn_secondary span{ 
        font-size: small;
        display: flex;
        justify-content: center;
        transform: none;
    }
    .sports_center{
        margin: 0 auto;
        display: block;
        height: auto;
    }
    .sports_container_link{padding: 25px;}
}

@media (max-width : 567px){
    .sports_container_link{margin-bottom: 0; padding: 0;}
    .sports_container_link:first-child{margin: 0}
}

@media (min-width: 568px){
    .sports_container_link{margin: 0; display: block;}
    .sports_container_link:first-child{margin:0;}
    .sports_container_link:last-child{margin: 0;}
}
