.feature-col .additional-info {font-size: 24px;}
.feature-col .prize-1 {font-size:30px;}
.feature-col .prize-1 .icon {color:#FFD700}
.feature-col .prize-2 {font-size:30px;}
.feature-col .prize-2 .icon {color:#C0C0C0}
.feature-col .prize-3 {font-size:30px;}
.feature-col .prize-3 .icon {color:#CD7F32}
.feature-col .extra-icon {display:none;}
.feature-col .icon-card {
    background-size: contain;
    background-repeat: no-repeat;
}
.feature-col .icon-card.icon-double {
    padding-left:30px;
    margin-left:5px;
    background-image: url('../../../public/assets/icons/double.svg');
}
.feature-col .icon-card.icon-triple {
    padding-left:45px;
    margin-left:5px;
    background-image: url('../../../public/assets/icons/triple.svg');
}

.label-description {font-size: smaller;font-weight: normal;}

.prize-limits {color:#666;font-size:12px;}

@media (max-width : 991px){
    .heading-ghana {text-align: center;}

    .feature-col .prize-1 {text-align: center;border-bottom:1px dashed #ccc;}
    .feature-col .prize-1 .icon {font-size: 40px;}
    .feature-col .prize-2 {text-align: center;border-bottom:1px dashed #ccc;}
    .feature-col .prize-2 .icon {font-size: 40px;}
    .feature-col .prize-3 {text-align: center;}
    .feature-col .prize-3 .icon {font-size: 40px;}
    .feature-col .extra-icon {display:inline-block;}
}

.register_container{ width: 100%;  background: #E0E0E0; padding: 2.5rem 3rem;}
.active_account_container{ width: 100%;  background: #E0E0E0; padding: 2.5rem 3rem;}
.register_input{border: 1px solid #12332E; color:#6F6F6F; border-radius: 6px; height: 45px; width: 100%; padding: 0 14px; outline: none;}
.register_container .btn_login{color: #539384; text-decoration: none; margin-left: 15px; font-weight: 600;}
.register_checkbox{width: 30px; height: 30px; border-radius: 6px; margin-right: 15px;}
.btn_already_have_acc{text-decoration: none; color: #12332E; font-weight: bold; margin-left: 10px;}
.already_have_acc_text{color: #12332E; font-weight: 400;}
.register_terms{color: #12332E; font-weight: 300;}
.btn_active_account{
    float: right;
    margin-top: 1rem !important;
}

.index_header .ghana_top_predictor_logo {max-width: 175px;}

@keyframes pulse {
	0% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.7);
	}

	70% {
		transform: scale(1);
		box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
	}

	100% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
	}
}

.go-to-register {
    background-color: #539384;
    color:#fff !important;
    border-radius: 5px;
    padding:5px;
    text-decoration: none;
    font-weight: bold;
    text-transform: uppercase;
    font-size:smaller;
    animation: pulse 2s infinite;
}

@media (max-width: 350px) {
    .go-to-register {display:none;}
}

@media (min-width : 992px){
    .ghana_index_header {height: 300px !important;}

    .ghana_player_mobile{display: none;}
    .ghana_guy_happy_mobile_logo{max-width: 100%; max-height: 100%; position: absolute; top: 0px; right: 50px;  margin-right: auto; z-index: 2;}
    

    .register_now_container{justify-content: flex-end;}
    .mt-row{margin-top: 1.5rem;}
    .btn_active_account{
        margin-top: 0px ;

    }
}

@media (max-width : 991px){
    .ghana_index_header {height: 200px !important;}
    .ghana_guy_happy_mobile_logo{display: none;}
    .ghana_player_mobile{display: none;} 
    /* {position: absolute; top: 0; left: 0; z-index: 2; max-height: 100%;} */

    .register_now_container{justify-content: center;  margin-top: 1.5rem; }
    .mt-row{margin-top: 0;}
    .btn_active_account{margin-top: 1rem;}
    .active_account_container{ width: 100%;  background: #E0E0E0; padding: 5.5rem 3rem;}
}