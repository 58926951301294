.create_private_competition_container{background: #F3F3F3; padding: 55px;}
.private_competition_select{width: 100%;background: #FFFFFF;  border: 1px solid #12332E;  box-sizing: border-box;  border-radius: 5px; height: 45px; color: #6F6F6F; padding: 0 15px;}
.private_competition_select_options{color: #12332E; font-size: 20px;}
.my_private_competition_container{background: #F3F3F3; padding: 55px;}
.my_competition_info, .my_competition_data{color: #12332E; background: #FFFFFF; height: 70px; margin: 15px 0; align-items: center;}
.my_competition_info{height: 45px; font-weight: bold;}
.copy_competition{margin-right: 30px;}
.copy_competition img{margin-right: 15px;}
.share_competition img{margin-right: 15px;}
.private_competition_i{margin-right: 15px;}
.my_private_competition_container img{height: 25px; width: 25px;}
.private_competition_leaderboard_i{margin-left: 15px;}
@media (max-width: 991px){
    .create_private_competition_btn_container{display: flex; justify-content: center;}
    .private_competition_sport_league div, .private_competition_entry_fee_entrants div{margin: 10px 0;}
    .create_private_competition_container{padding: 25px;}
    .my_private_competition_container{padding: 25px;}
    .my_competition_info{ height: auto;}
    .my_competition_data{ height: auto; padding: 15px 0;}
}

@media (min-width: 992px){
    .create_private_competition_btn_container{display: flex; justify-content: flex-end;}
    .private_competition_sport_league, .private_competition_entry_fee_entrants{margin: 20px 0; }
}
