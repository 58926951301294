.faqs_container{width: 100%;  background: #F3F3F3;padding: 55px;}
.faqs_header{text-align: center; color: #12332E; font-weight: bold; font-style: italic; margin-bottom: 60px;   margin-top: 10px; font-family: 'Roboto';}
.faqs_container .accordion{margin-top: 20px;}
.faqs_container .card-header{background: #539384; color: #FFFFFF; font-weight: 600; letter-spacing: 0.05em; padding: 5px 40px; border-radius: 10px; cursor: pointer;}
.faqs_container .card{background: #F3F3F3; padding: 10px 0px; color: #12332E; font-weight: 300; border-radius: 0 10px 10px 10px; border: rgba(255, 255, 255, 0);}
.faqs_container h2{font-weight: bold;}
#faqs_input{width: 100%; height: 45px; background: #FFFFFF;  border: 1px solid #12332E; box-sizing: border-box;  border-radius: 10px; padding: 0 40px;}
#faqs_input::placeholder{font-weight: 300; color: #C9C9C9;}
.faqs_search{margin-bottom: 45px;}
.search_icon{position: absolute; margin-top: 10px; margin-left: 10px;}

@media(max-width : 991px){
    .faqs_container{padding: 30px;}
}

@media(min-width : 992px){
    .faqs_container{padding: 80px;}
}

