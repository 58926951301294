.challange_container{
    width: 100%;
    background: #F3F3F3;
    padding: 55px;
}
.challange_text{
    text-align: center;
    color:  #12332E;
    font-style: italic;
    font-weight: bold;
    margin-bottom: 85px;
    font-family: 'Roboto';
}
.challange_text h2{font-weight: bold;}
.challange_club_fundraising{
    color: #12332E;
    text-align: center;
    font-size: 24px;
    font-weight: 600;
    font-style: italic;
}
.cooming_soon{
    color: #D99D0B;;
}
.title{
    font-size: 18px;
    font-weight: bold;
}
.challange_center_container{margin-top: 73px;}
.club_fundrising{color: #12332E;}
.title{color: #12332E;}
.challange_club_fundraising h4{font-weight: bold; letter-spacing: 0.05em; font-family: 'Roboto';}

@media(max-width: 991px){
    .challange_container{padding: 55px;}
}

@media(min-width: 992px){
    .challange_container{padding: 80px;}
}
