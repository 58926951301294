@import url(https://fonts.googleapis.com/css?family=Roboto:400,100,100italic,300,300ita‌​lic,400italic,500,500italic,700,700italic,900italic,900);
html,
body {
  height: 100%;
  width: 100%;
  font-family: "Roboto Condensed" !important;
}
#root {
  height: 100%;
  width: 100%;
}
.App {
  height: 100%;
  width: 100%;
}

/*  Footer */
.footer {
  width: auto;
  height: auto;
  bottom: 0px;
  background-color: #12332E;
}

.footer .row.footer_container {
  height: 100%;
  display: flex;
}
.age_allowed_container {
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #a5a5a5;
  border-radius: 50%;
  color: #12332E;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
}
.footer .copyright{
  text-align: center;
  color: #ffffff;
  font-size: 14px;
  font-weight: 400;
}

.gambling-link{
  text-align: center;
  color: #ffffff !important;
  font-size: 14px;
  font-weight: 400;
  text-decoration: none;
}
.footer_content ul {
  padding: 0;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 18px;
  letter-spacing: 0.05em;
  color: #ffb500 !important;
}
.footer_content ul li {
  display: inline-block;
}
.footer_content ul li {
  text-decoration: none;
}
.footer_nav_link {
  color: #ffb500 !important;
  font-size: 16px;
  padding-right: 15px;
}
.footer_nav_link:hover {
  color: #ffffff !important;
}
.footer_nav_link.active{
  color: #ffffff !important;
}
.footer_logos_container {
  width: fit-content !important;
  display: flex;
}
.footer_logos_container > * {
  margin: 0 10px;
}

/* Header */
.header_container {
  height: auto;
  /* padding: 2.5rem 3rem; */
  width: 100%;
  /* background-image: url("./components/Header/images/concrete-wall.png"); */
  /* background-image: url("../public/assets/image/sports.png"); */
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  padding-bottom: 50px;
}
.top_predictor_logo {
  max-width: 262px;
}

.btn_view_all_sports span {
  display: inline-block;
  transform: skew(20deg);
  letter-spacing: 0.05em;
}

.btn_how_to_play {
  padding: 0px 10px;
}

.btn_how_to_play.active:hover {
  box-shadow: none;
}

.header_links {
  padding: 0;
  margin: 0;
}

.header_links li {
  list-style-type: none;
  display: inline-block;
  padding: 0 20px;
  color: #ffffff;
  font-size: 16px;
  font-weight: 600;
}

.btn_my_competitions span {
  display: inline-block;
  /* transform: skew(20deg); */
}

.navbar_icon {
  height: 20px;
  width: 20px;
}

.header_balance .balance {
  color: #ffffff;
  border: 2px solid #ffb500;
  padding: 2px 15px;
  border-radius: 4px;
  font-size: 12px;
}
.balance_border {
  width: 33px;
  height: 1.5px;
  background: #ffb500;
  margin-left: -8px;
}
.header_right_links {
  float: right !important;
}

.footer .footer_content_text {
  padding: 10px 0px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.05em;
  color: #ffffff;
}

/* HEADER MOBILE */

.navbar-nav {
  width: 100%;
}
.navbar-nav a {
  color: white !important;
  cursor: pointer;
  text-decoration: none;
}
.btn_user_menu {
  background: transparent;
  border: none;
}
.btn_user_notifications {
  background: transparent;
  border: none;
}

.footer_logos_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.right_align_navbar {
  font-weight: 700;
  letter-spacing: 0.05em;
}

.right_align_navbar .btn_my_profile {
  display: none;
}
.right_align_navbar .btn_my_account {
  display: none;
}
.right_align_navbar .btn_gambling_safer {
  display: none;
}
.right_align_navbar .btn_log_out {
  display: none;
}

.center_container {
  max-width: 1440px;
  margin: 0 auto;
  padding: 45px 40px;
}
.page_center {
  max-width: 1440px !important;
  margin: 0 auto;
}

.show_password {
  position: absolute;
  right: 20px;
  top: 10px;
  cursor: pointer;
}
.total_notifications {
  width: 20px;
  height: 20px;
  display: flex;
  background: #ffb500;
  border-radius: 50%;
  position: absolute;
  left: 10px;
  bottom: 15px;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  color: #000000;
  letter-spacing: 0.05em;
}
.total_notifications span {
  height: 100%;
  text-align: center;
  margin-bottom: 3px;
  margin-left: 1px;
}

.page-footer {
  max-width: 1440px;
  margin: 0 auto;
  padding: 55px;
  padding-bottom: 20px;
}
.index_center {
  max-width: 1440px;
  width: 100%;
  height: 100%;
  margin: auto;
}
.page_content_center {
  max-width: 1200px !important;
  margin: 0 auto;
  padding: 30px 55px;
}
.header_container .navbar {
  max-width: 1440px;
  margin: 0 auto;
  padding-bottom: 0;
}
.balance_row {
  max-width: 1440px;
  margin: 0 auto !important;
  width: 100%;
  padding: 25px 55px 0;
}
.balance_row .header_balance {
  padding: 0;
  justify-content: flex-end;
}
.bold_font {
  font-weight: bold;
}
.page_content_center input {
  padding: 0 12px;
  font-size: 14px;
}
.page_content_center select {
  padding: 0 12px;
  font-size: 14px;
}
.required_message {
  margin-top: 10px;
  display: inline-block;
  color: #539384;
  font-size: 14px;
  font-weight: 400;
}

.allsports_btn {
  display: none;
}

.success_notification {
  max-width: 1200px;
  margin: 0 auto;
}
.success_message {
  background: #12332e;
  border-radius: 6px;
  padding: 15px;
}
.check_icon_container {
  border-radius: 50%;
  background: #ffffff;
  border-radius: 50%;
  background: #fff;
  height: 35px;
  width: 35px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}
.check_icon_container img {
  width: 25px;
  height: 25px;
}
.success_message .message {
  color: #ffffff;
  margin-left: 35px;
  width: 80%;
}
.success_message .message_content {
  display: block;
  font-weight: 700;
  font-family: 'Roboto';
}
.success_message .message_title {
  font-weight: bold;
  font-family: 'Roboto';
}
.btn_primary.accept {
  padding: 10px;
  width: auto;
  height: 35px;
  color: #fff;
  text-transform: uppercase;
  background: #539384;
  border-radius: 5px;
  box-shadow: 8px 6px 4px rgb(0 0 0 / 75%);
  -webkit-transform: skew(-20deg);
  transform: skew(-20deg);
  border: none;
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pagination .page{
  width: 30px;
  height: 30px;
  background: #FFFFFF;
  border: 1px solid #12332E;
  box-sizing: border-box;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  cursor: pointer;
  align-items: center;
  margin: 0 3px;
  color: #539384;
  font-family: 'Roboto Condensed';
}
.pagination .page.active{
  background: #539384;
  color: #FFFFFF;
}
.no_available_leagues{text-align: center; font-family: 'Roboto';}
.header_label{text-transform: uppercase;}
.match_started_message{font-size: 18px;  margin-right: 50px;}
.create_new_private{display: flex; align-items: center; justify-content: center; text-decoration: none; }
.create_new_private span{color: #fff !important;text-decoration: none; display: flex; align-items: center;justify-content: center;
  transform: skew(20deg);
  letter-spacing: 0.05em;
  font-weight: bold;
  font-weight: 600;font-style: normal;}

  .gigadat_logo{height:50px; margin-left: 10px;}
  .mastercard_logo{
    width: 100px; margin-left: 5px;
  }

@media (min-width: 992px) {
  .my_competitions_container {
    min-height: 100%;
  }
  body {
    padding-bottom: 400px;
  }
  .footer {
    position: relative;
    width: 100%;
    z-index: 999;
  }
  .right_align_navbar {
    margin-left: auto;
    margin-right: 0;
  }

  .btn_user_menu {
    padding-left: 10px;
  }
  .btn_view_all_sports {
    margin-right: 20px !important;
  }
  .btn_view_all_sports.active {
    box-shadow: none;
  }
  .right_align_navbar {
    display: flex;
    align-items: center;
  }

  .btn_my_competitions {
    background: transparent;
    border-radius: 5px 8px 8px 8px;
    color: #ffffff;
    font-size: 16px;
    font-weight: 600;
    transform: skew(-20deg);
    text-decoration: none;
    padding: 0 10px;
    text-align: center;
  }
  .header_btn {
    margin-right: 20px;
  }

  .footer .row.footer_container {
    display: flex;
    justify-content: space-between;
  }

  .header_balance {
    justify-content: end;
  }
  .header_container .navbar {
    padding: 40px 55px;
    padding-bottom: 0;
  }
  .btn_user_notifications_mobile {
    display: none;
  }

  .footer .page-footer{
    padding: 55px;
  }
  .btn_my_competitions.header_btn{margin-right: 10px;}
  .match_started_container{display: flex; align-items: center;}
}

@media (max-width: 991px) {
  .navbar-nav a {
    display: block;
    padding: 3px 0;
  }
  .footer .row.footer_container {
    display: flex;
    justify-content: center;
    padding: 20px;
  }

  .right_align_navbar {
    padding: 10px;
  }
  .header_balance {
    justify-content: end;
    padding: 0 20px !important;
  }
  .navbar-nav {
    text-align: center;
    background: #12332E;
    color: #fff;
    position: absolute;
    font-size: 20px;
    z-index: 4;
    left: 0;
    margin-top: 15px;
  }

  .navbar-nav .btn_user_notifications {
    display: none;
  }

  .navbar-toggler {
    background-color: #e0e0e0 !important;
    /* padding: 2rem !important; */
  }

  /* .right_align_navbar .mobile_navbar{ */
  /* position: relative;
    bottom: 32px; */
  /* } */

  .btn_how_to_play.active {
    color: #ffb500;
  }

  .payments_logos{display: flex;
    justify-content: center;
    align-items: center;}
  .gigadat_logo{width: 100px; height:50px;  margin-left: 0px;}
  .mastercard_logo{width: 100px; height: auto; margin-left: 8px !important;}
  .visa_logo{width: 100px; height: 45px;}

  .right_align_navbar .btn_view_all_sports {
    position: absolute;
    bottom: 20px;
    left: 30px;
    width: 250px;
    height: 45px;
    display: none;
  }

  .allsports_btn {
    display: flex !important;
    justify-content: center;
    align-items: center;
  }

  .mobile_all_sports_btn {
    display: flex;
    margin-top: 25px;
    justify-content: center;
  }
  .all_sports_container {
    margin-top: 135px !important;
  }

  .right_align_navbar .notification_icon {
    display: none;
  }
  .right_align_navbar .btn_user_menu {
    display: none;
  }
  .right_align_navbar .btn_my_profile {
    display: flex;
  }
  .right_align_navbar .btn_my_account {
    display: flex;
  }
  .right_align_navbar .btn_gambling_safer {
    display: flex;
  }
  .right_align_navbar .btn_log_out {
    display: flex;
  }
  .right_align_navbar .mobile_navbar .btn_how_to_play {
    display: flex;
  }
  .right_align_navbar .mobile_navbar .btn_my_competitions {
    display: flex;
  }

  .footer_logos_container {
    justify-content: space-between;
    align-items: center;
    position: absolute;
  }
  .footer_content {
    position: relative;
    margin-top: 145px !important;
  }
  .footer_content ul {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 35px;
    text-align: center;
    letter-spacing: 0.05em;
  }
  .footer_content_text {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    letter-spacing: 0.05em;
  }
  .right_align_navbar {
    display: block;
  }
  .age_allowed_container {
    max-width: 150px;
  }
  .footer_icon {
    max-width: 150px;
  }
  .btn_my_competitions {
    text-decoration: none;
  }
  .footer_icon_logo {
    max-width: 150px;
  }
  .header_btn {
    margin-right: 0;
  }

  .top_predictor_logo {
    max-width: 120px;
  }
  .header_balance {
    justify-content: center;
  }
  .balance_border {
    width: 0px;
    height: 1.5px;
    background: none;
    margin-left: -8px;
  }

  .header_container .navbar-nav {
    padding: 0;
    margin-top: 11px;
    height: auto;
    padding-left: 30px;
    border-radius: 5px;
    box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.65);
  }
  .header_container .navbar-nav a {
    font-family: "Roboto Condensed";
    font-style: italic;
    font-weight: bold;
    margin: 8px 0;
  }
  .header_container .navbar {
    padding: 20px;
  }
  .balance_row .header_balance {
    justify-content: center;
  }
  .page-footer {
    padding: 20px;
  }
  .btn_user_notifications_mobile {
    display: inline-block;
    margin-right: 10px;
  }
  .footer .page-footer{
    padding: 25px;
  }

}
