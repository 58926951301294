.profile_container{ width: auto; height: auto;left: 0px; background: #F3F3F3;}
.profile_container .account{background: #12332E; width: auto; display: flex; align-items: center; justify-content: center; height: 45px; border-radius: 5px;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.55);
    color: #D99D0B;
    padding: 0 25px;
    font-style: italic;
    font-weight: bold;
}
.general_information_container label{color: #12332E; font-size: 22px; font-weight: bold;}
.general_information_container{margin-top: 45px;}
.profile_information{padding: 45px 0;}
.profile_input{border: 1px solid #12332E; color:#000000; border-radius: 6px; height: 45px; padding: 0 14px; width: 100%; outline: none; font-size: 14px;}
.upload_container .upload{ width: 250px; height: 100px; border: 1px solid #12332E; border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #539384;
    font-size: 16px;
    font-style: italic;
    font-weight: bold;
    background: #FFFFFF;
    cursor: pointer;
}
.upload_icon{width: 35px; height: 35px; margin-top: 8px;}
.general_information_footer{margin-top: 45px; justify-content: flex-end;}
.change_password_container{  width: auto;height: auto; left: 0px;background: #F3F3F3;}
.change_password_container .lbl_change_password{color: #12332E; font-size: 22px; font-weight: bold; margin-bottom: 45px;}
.chnage_password_center{max-width: 500px; margin: 0 auto;}
.my_profile{background: #12332E; height: 75px; color: #FFFFFF; display: flex; align-items: center;}
.my_profile_center{width: 1200px;margin: 0 auto; display: flex;justify-content: space-between;padding: 0 55px; align-items: center;}
.username{font-size: 12px; font-weight: 400; display: block;}
.my_profile .lbl_my_profile{font-size: 22px; font-weight: 700; color: #FFFFFF;}
.hello_user{font-size: 22px; font-weight: 700; font-style: italic;}
.profile_input:read-only {cursor: no-drop;}
.general_information_container input::placeholder{text-transform: capitalize;}
.change_password_container input::placeholder{text-transform: capitalize;}


.general_information{background: #FFFFFF;  border-radius: 10px; padding: 15px; font-family: 'Roboto'; font-weight: 400; margin-top: 30px;}
.general_info_label{color: #000000; font-weight: bold;}
.general_info_data .general_info_label{width: 150px;}

@media (min-width : 992px){
    .account_status{display: flex; justify-content: space-between; align-items: center;}
    .upload_container{display: flex; justify-content: space-between; align-items: center;}
    .general_information_footer{display: flex; justify-content: flex-end;}
    .general_information_footer .btn_save_profile{margin-left: 15px;}
    .update_password_btn_container{display: flex; justify-content: flex-end;}
    .general_info_address{text-align: end;}
    .general_info_save_container{margin-top: 25px; justify-content: flex-end;}
    .profile_input{width: 250px;}

}
@media (max-width: 991px){
    .profile_input{margin-top: 25px;}
    .account_status{text-align: center;}
    .account_status .btn_change_password{margin-top: 15px;}
    .upload_container{display: flex; flex-direction: column;align-items: center;}
    .upload_container .upload{margin-bottom: 10px;}
    .general_information_footer{text-align: center;}
    .general_information_footer .btn_save_profile{margin-top: 15px;}
    .update_password_btn_container{display: flex; justify-content: center; margin-top: 15px;}
    .my_profile_center{padding: 0;}
    .general_information_container{text-align: center;}
    .my_profile{position: relative; height: 120px; padding: 25px;}
    .my_profile .lbl_my_profile{position: absolute; bottom: 10px;}
    .user_profile_info{ position: absolute; top: 10px;}
    .profile_container{padding: 25px;}
    .general_info_address, .general_info_data{text-align: start;}
    .general_info_save_container{margin-top: 25px; justify-content: center;}
    .profile_input{width: 100%;}
    .account_status_container{display: flex; justify-content: center; margin-bottom: 20px;}
}
div.sumsub_container > div:first-child{width: 100%;}
#input_profile_country:disabled{background-color: #f0f0f0;}
#input_profile_state:disabled{background-color: #f0f0f0;}
#input_profile_phone_no{color: #000000;}
#input_profile_phone_no::placeholder{color: #000000;}