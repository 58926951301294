.image_conainer{
    background-color: #12332E;
    max-height: 250px;
    height: 100%;
}
@media (max-width: 991px){
    .image_conainer{
        height: auto;
        padding: 0 30px;
    }
    .background_image img{
        width: 100%;
        max-height: 225px;
    }
}

@media (min-width: 991px){
    .image_conainer{
        height: auto;
        padding: 0 120px;
    }
    .background_image img{
        max-height: 250px;
    }
}