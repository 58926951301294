.create_club_fundraiser_container{background: #F3F3F3; padding: 55px;}
.create_club_fundraiser_select{width: 100%;background: #FFFFFF;  border: 1px solid #12332E;  box-sizing: border-box;  border-radius: 5px; height: 35px; color: #6F6F6F; padding: 0 15px;}
.create_club_fundraiser_select_mini{max-width: 70px;background: #FFFFFF;  border: 1px solid #12332E;  box-sizing: border-box;  border-radius: 5px; height: 35px; color: #6F6F6F; padding: 0 15px;}
.organization_name_input, .account_name_input, .sort_code_input, .account_number_input, .competition_title_input, .currency_select{border: 1px solid #12332E; color:#6F6F6F; font-size: 14px; border-radius: 6px; height: 35px; padding: 0 14px; width: 100%; outline: none;}
.club_fundraiser_container_tittle{margin-bottom: 30px;}
.club_fundraiser_tittle{font-family: 'Roboto';font-style: normal;font-weight: bold;}
.club_fundraiser_details{font-family: 'Roboto';font-style: normal;font-weight: 200;font-size: 15px;line-height: 30px;color: #12332E; }
.club_fundraiser_bold{font-weight: bold; }
.club_fundraiser_select_options_tittle{margin-top:50px;}
.mini_margin_left{margin-left: 10px; font-family: 'Roboto';font-style: normal;font-weight: normal;font-size: 14px;line-height: 40px; color: #6F6F6F;}
.create_club_fundraiser_info{padding: 0 12px;}
.club_fundraiser_selections{padding: 0 12px;}
.create_club_fundraiser_btn div{display: flex; justify-content: flex-end;}
.light_color{color: #6F6F6F; font-weight: bold; font-size: 14px;}
.competition_title_input{margin-bottom: 0;}
.create_club_fundraiser_container select{font-size: 14px;}
.fundraiser_currency_sign{margin-right: 5px;}
.red_color{background-color: red;}

@media (max-width: 991px){
    .create_club_fundraiser_container{display: flex; justify-content: center; padding: 25px;}
    .club_fundraiser_tittle div, .club_fundraiser_sports div, .club_fundraiser_team div, .club_fundraiser_entry_fee div, .club_fundraiser_entrants div, .create_club_fundraiser_btn div{margin: 10px 0;}
    .create_club_fundraiser_info{padding: 12px;}
    .club_fundraiser_tittle{font-size: 18px;line-height: 23px;display: flex;align-items: center;}
    .mobile_club_fundraiser_details{display: flex;align-items: center; margin-top: 25px;}
    .organization_name_input, .account_name_input, .sort_code_input, .account_number_input, .competition_title_input{margin: 10px 0;}
    .create_club_fundraiser_btn div{display: flex; justify-content: center;}
    .competition_title_input{margin-bottom: 0;}
    .club_fundraiser_col{position: relative;}
    .club_fundraiser_right{position: absolute; right: 0; top: -27px;}
    .club_fundraiser_col select{margin-top: 20px; width: 100%; margin-left: 0;}
}

@media (min-width: 991px){
    .create_club_fundraiser_select_mini{width: 60%;}
    .fundraiser_currency_col{display: flex; align-items: center;}
    .club_fundraiser_col .light_color{width: 60px; display: inline-block;}
}