.btn_account_action span {
  transform: skew(20deg);
}

.sport_button {
  margin: 50px;
  transform: skew(-20deg);
  width: 300px;
  padding: 5px;
  border: 2px solid #c7c7c7;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
  color: #c7c7c7;
}

.sport_button span {
  transform: skew(20deg);
  letter-spacing: 0.05em;
  font-weight: bold;
  font-size: 22px;
}

.sport_button:hover {
  background: #d99d0b;
  color: #1a483e;
  border: 2px solid #d99d0b;
  box-shadow: 1px 4px 1px 0 rgb(0 0 0 / 50%);
}

.btn_primary {
  width: 195px;
  height: 35px;
  color: #ffffff;
  text-transform: uppercase;
  background: #539384;
  border-radius: 5px;
  box-shadow: 8px 6px 4px rgb(0 0 0 / 75%);
  transform: skew(-20deg);
  border: none;
  margin-right: 10px;
}
.btn_primary span {
  transform: skew(20deg);
  font-weight: bold;
  display: inline-block;
}
.btn_submit_feedback.active {
  box-shadow: none;
}
.btn_account_action.active {
  box-shadow: none;
}

.btn_secondary {
  width: 195px;
  height: 35px;
  color: #ffffff;
  text-transform: uppercase;
  background: #d99d0b;
  border-radius: 5px;
  box-shadow: 8px 6px 4px rgb(0 0 0 / 75%);
  transform: skew(-20deg);
  border: none;
  margin-right: 10px;
  font-family: "Roboto Condensed";
}
.btn_secondary span {
  transform: skew(20deg);
  font-weight: bold;
  display: inline-block;
}

.btn_safer_gambling.active {
  background: #12332e;
}
.btn_light {
  width: 195px;
  height: 35px;
  color: #539384;
  text-transform: uppercase;
  background: transparent;
  border-radius: 5px;
  transform: skew(-20deg);
  border: none;
  margin-right: 10px;
  border: 2px solid #539384;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.btn_light.active {
  box-shadow: none;
}
.btn_light span {
  transform: skew(20deg);
  font-weight: bold;
  display: inline-block;
}
.btn_account_deposit.active {
  background: #12332e;
}
.btn_account_withdraw.active {
  background: #12332e;
}
.btn_account_transactions.active {
  background: #12332e;
}
.btn_completed_competitions.active {
  background: #12332e;
}
.btn_live_competitions.active {
  background: #12332e;
}
.btn_live_competitions,
.btn_completed_competitions {
  margin: 0 30px;
}

@media (max-width: 991px) {
  .sports_button_container {
    display: none;
  }
  .sport_button {
    width: 200px;
    margin: 20px;
  }
  .sports_btns {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

@media (min-width: 992px) {
  .sports_button_container {
    display: flex;
    justify-content: center;
  }
  .sport_button {
    width: 300px;
  }
}
