/*OUR MISSION*/
.our_mission_container{
    width: auto;
    height: auto;
    left: 0px;
    background: #F3F3F3;
    padding: 55px;
}
.our_mission_title{    
    text-align: center;
    margin-bottom: 60px;
    margin-top: 10px;
}
.our_mission_title h2{
    font-family: 'Roboto';
    font-style: italic;
    font-weight: bold;
    color: #12332E;
}
.our_mission_content{color: #12332E;  font-family: 'Roboto';}

@media(max-width : 991px){
    .our_mission_container{padding: 55px;}
}

@media(min-width : 992px){
    .our_mission_container{padding: 80px;}
}