.sports_league_container{width: 100%;  background: #F3F3F3; padding: 25px 55px; color: #12332E;}
.sports_league_header{color: #12332E; font-weight: normal; font-style: italic; font-family: 'Roboto'; font-size: 16px;line-height: 35px;display: flex; justify-content: center;align-items: center;text-align: center;}
.sports_league_btns{ margin-top: 50px; display: flex; justify-content: center;}

.no_text_dec{color: white; text-decoration: none;}
.no_text_dec:hover{color: #539384;}
.sports_league{background: #12332E; height: 75px; color: #FFFFFF; display: flex; align-items: center; padding: 0 45px; justify-content: center;}
.sports_league .my_sports_league{max-width: 1440px; margin: 0 auto; padding: 10px 20px;}
.lbl_sports_league{font-weight: 700; color: #FFFFFF;font-family: 'Roboto'; font-style: normal;font-weight: bold; text-align: center;}
.btn_sports_league span{transform: skew(20deg);}
.sports_league_container .sports_league_btns button{margin: 0 40px;}

.sports_league_container .btn_container button{width: 230px;}
.sports_league_select_competition{color: #12332E; font-weight: bold; text-align: center;}
.sports_league_container .page_content_center{max-width: 1300px !important;}
.sports_league_container .my_sports_league_center{max-width: 1300px; margin: 0 auto; padding: 0 55px;}

@media (max-width: 991px) {
    .sports_league_btns{display: block;}
    .sports_league_btns button{height: 50px;}
    .row .transaction{flex-direction: column;margin-bottom: 25px !important;border-radius: 6px;}
    .challanges_entries{width: 100%;}
    .challanges_price_pool{width: 100%; text-align: left;}
    .challanges_entry_fee{width: 100%; text-align: left;}
    .challanges_icons_col{width: 100%;}
    .sports_league_container .my_sports_league_center{max-width: 1300px; margin: 0 auto; padding: 0 25px;}
    .sports_league_container{padding: 20px;}
}

@media (min-width: 992px){
    .challanges_entries{width: 100px;}
    .challanges_price_pool{width: 100px; text-align: right;}
    .challanges_entry_fee{width: 120px; text-align: right;}
    .challanges_icons_col{width: 80px;}
}
