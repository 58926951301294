.notifications_container{background: #F3F3F3; font-family: 'Roboto';}
.notifications{background: #12332E; color: #ffffff; height: 75px; display: flex; align-items: center;}
.notifications label{color: #ffffff;}
.notifications_center{display: flex;justify-content: space-between; width: 100%; align-items: center; max-width: 1200px; margin: 0 auto;}
.my_notifications_container{background: #F3F3F3; color: #12332E; font-weight: 400; }
.my_notifications_container.no_notifications{ display: flex;  align-items: center; justify-content: center; font-size: 22px;}
.notification_col{width:100%; margin: 10px 0;}
.notifications_title{background-color: #ffffff;}
.notification_title{font-weight: 500}

@media (min-width : 992px){
    .my_notification_container {
        min-height: 100%;
      }
}
@media (max-width: 991px){
    .notifications_center{padding: 15px;}
    .notifications{position: relative;height: 120px; }
    .user_profile_info {  position: absolute; top: 10px; }
    .lbl_notifications{ position: absolute; bottom: 10px;}
    .no_notifications_message{text-align: center;}
    .my_notifications_container{padding-left: 35px;
        padding-right: 30px;
        margin-top: 45px;}

}