.feedback_container{
    width: 100%;
    background: #F3F3F3;
    padding: 55px;
}
.feedback_text{
    text-align: center;
    color: #12332E;
    font-size: 26px;
    font-weight: bold;
    font-style: italic;
}
.feedback_text h2{font-weight: bold;}
.feedback_center{
    color: #12332E;
    font-size: 16px;
    font-weight: 300;
}
.feedback_textarea{
    border-radius: 6px;
    width: 100%;
    min-height: 200px;
    padding: 15px;
    outline: none;
    background: #FFFFFF;
    border: 1px solid #12332E;
    box-sizing: border-box;
}
.feedback_textarea::placeholder{
    color: #6F6F6F;
    font-size: 14px;
}

.btn_submit_feedback span{
    transform: skew(20deg);
    font-weight: bold;
    display: inline-block;
}
.feedback_input{
    border-radius: 6px;
    width: 100%;
    padding: 15px;
    height: 45px;
    outline: none;
    background: #FFFFFF;
    border: 1px solid #12332E;
    box-sizing: border-box;
}
