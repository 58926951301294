.all_sports_container {
  background: #12332E;
  color: #fff;
  padding: 2rem;
  width: 100%;
  position: absolute;
  z-index: 5;
  left: 0;
  margin-top: 15px;
}

.svg-white {
  filter: invert(100%) sepia(0%) saturate(7480%) hue-rotate(62deg) brightness(100%) contrast(98%); /* Change the image to grayscale, effectively making it white */
}

.all_sports_container .title {
  color: #539384;
}

.all_sports_container .sports_container {
  width: 100%;
  height: 100%;
  padding: 0;
}

.sports_container {
  display: flex;
  justify-content: center;
}

.all_sports_container .type {
  display: block;
  margin: 4px 0;
  letter-spacing: 0.05em;
}
.all_sports_container .sport_type:hover,
.all_sports_container .sport_league:hover {
  color: #539384;
  cursor: pointer;
}
.all_sports_container .sport {
  display: inline-block;
  margin-right: 150px;
}
.all_sports_container .sport:last-child {
  margin: 0;
}
.subtype_container {
  flex-direction: column;
  padding: 0 0 0 20px;
}
.subtype_container span {
  cursor: pointer;
}
.subtype_container span:hover {
  color: #539384;
}
.allsports_bottom {
  position: absolute;
  bottom: 0;
  height: 5px;
  height: 10px;
  left: 0;
  width: 100%;
  background: #539384;
}
.sport_league {
  margin-left: 20px;
  display: none;
}
.sport_type {
  display: block;
}
.no_active_competitions{text-align: center; font-size: 18px;}
@media (max-width: 991px) {
  .all_sports_container {
    height: auto;
  }
  .all_sports_container .sports_container {
    display: block;
  }
  .all_sports_container .sport {
    display: block;
  }
}

@media (max-width: 600px) {.homeModal .modal-content {padding: 0 !important; height: 350px !important;}}
.kyc_title_warning{font-size: 20px; color: #6F6F6F;}
.kyc_body_warning{font-size: 18px; color: #6F6F6F;}
.homeModal .modal-content {height: 300px; width: 800px; padding: 30px;}
.homeModal .modal-dialog{max-width: 800px !important;}
.golf-modal{height: auto!important; width: 800px; padding: 30px;}
@media (max-width: 600px) {.golfModal .modal-content {padding: 0 !important; height: auto !important;}}
.golfModal .modal-content {height: auto; width: 1000px; padding: 30px;}
.golfModal .modal-dialog{max-width: 1000px !important;}