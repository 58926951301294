.features {font-size:2em;}

.carousel-control-next .carousel-control-next-icon {background-color: #d99d0b;border-radius: 5px;}
.carousel-control-prev .carousel-control-prev-icon {background-color: #d99d0b;border-radius: 5px;}
.carousel-caption {
    left:0px;
    right:0px;
    bottom:0px;
    padding-top:0.5rem;
    padding-bottom:2rem;
    background: #00000090;
     /* linear-gradient(to top, rgba(0, 0, 0, 0.85), transparent); */
    color:#fff;
    text-shadow: 0 2px 3px rgba(0, 0, 0, 0.6);
    padding-left:5%;
    padding-right:5%;
}

.carousel-indicators {height:5px;bottom:5px;}

.carousel-container-step-by-step {
    /* border-radius: 25px;
    border:10px solid #d99d0b;
    width:320px; */
}
.carousel.step-by-step .carousel-caption {
    height: 120px;
    padding-bottom:0;
    text-align: left;
}



.index_container{width: 100%;}
.index_header{background-size: cover; background-repeat: no-repeat; height: 600px; position: relative;}
.index_header .nav{display: flex; align-items: center; justify-content: space-between; max-width: 1440px; margin: 0 auto; padding: 40px 55px;}
.index_header_buttons a{text-decoration: none; color: #1A483E; font-weight: 600; letter-spacing: 0.05em; font-style: italic; }
.index_header_buttons a span{padding: 0px 10px; justify-content: center;}
.btn_go_to_login{margin-left: 35px;}
.index_header_center{background: #000000; height: 300px; width: 100%; position: absolute; bottom: 0; z-index: 1;background-position: center; background-size: cover;}
.index_header_center{display: flex;}
.index_header_center a{width: 170px; height: 30px; border: none; transform: skew(-20deg); text-decoration: none; display: flex; justify-content: center; align-items: center;}
.index_header_center a span{transform: skew(20deg);}
.index_header_center .btn_signup{background: #539384; color: #ffffff; font-weight: bold; letter-spacing: 0.05em; border-us: 10px;}
.index_header_center .btn_login{background: #D99D0B; color: #ffffff; font-weight: bold; letter-spacing: 0.05em; border-radius: 10px; margin-top: 15px;}
.index_header_bottom{position: absolute; bottom: 0; height: 15px; width: 100%; background: #000000;}

.slider_container{height: auto; padding: 40px; display: flex;justify-content: center;align-items: center;}
/* .slider { width: 30%; float: left;margin:10px; background: rgba(0, 0, 0, 0.45); border-radius: 20px; transform: skew(-20deg);}
#sliders {width: 100%;}
.slider_content{transform: skew(20deg);}
.slider_content img{max-width: 100%; max-height: 100%;} */

.slider{
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.slide{
    background-size: cover;
    background-position: center;
    display: inline-block;
    /* filter: drop-shadow(25px 0px 35px rgba(0, 0, 0, 0.55)); */
    /*max-width: 300px;*/
    width: 100%;
    position: relative;
}
.landing_slide{ background-size: cover; background-position: center; display: inline-block; filter: drop-shadow(25px 0px 35px rgba(0, 0, 0, 0.55)); max-width: 270px; width: 100%; position: relative;}
.slide p{margin-bottom: 0;}
.vertical_sport_link{margin: 0 15px;}
.slide img{
    width: 100%;
    height: inherit;
}

.responsive-img {
    max-width: 100%;
    height: auto;
    display: block; /* Centers the image horizontally */
    margin: 0 auto;
}

.slide.first img{object-fit: cover;}
.slide.third img{object-fit: cover;}

.slide.active{
    /* height:400px; */
    opacity: 1;
    /* left: 50%;
    transform: translateX(2%); */
    max-width: 350px;
    width: 100%;
    z-index: 4;
    filter: drop-shadow(25px 0px 35px rgba(0, 0, 0, 0.55));
}
.styles-module_item-container__a8zaY{
    display: flex;
    justify-content: center;
}
.styles-module_carousel-arrow__26sRw{
    background: #ffb500;
    border: none;
    height: 4px;
    width: 22px;
    position: absolute;
    border-radius: 4px;
}
.styles-module_carousel-base__3keqD {display: flex; align-items: center;}

.slide_text{
    position: absolute;
    bottom: 35px;
    left: 25px;
    z-index: 3;
}
.ipl_text{
    display: block;
    font-size: 20px;
    font-style: italic;
    color: white;
    font-weight: bold;
    letter-spacing: 0.05em;
    max-width: 200px;
}
.coming_soon_text{
    color: #ffb500;
    font-size: 13px;
    font-weight: bold;
    font-style: italic;
    letter-spacing: 0.05em;
}
.display_name_text:hover{
    color: #ffb500;
}
.display_name_active{
    color: #ffb500 !important;
}
.display_name_text{
    color: #fff;
    cursor: pointer;
    font-size: 18px;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 0.05em;
}
.click_here_text{color: #ffb500; font-size: 13px;font-weight: bold; letter-spacing: 0.05em; border-bottom: 2px solid #ffb500; display: block; width: fit-content;}

.sports_newest_container{
    line-height: .75em;
    font-style: italic;
    font-weight: bold;
    letter-spacing: -5px;
    position: absolute;
    margin-right: auto;
    z-index: 3;
}

.navbar-toggler{ background-color: transparent !important;  border: none;}
.index_container .navbar-nav{justify-content: flex-end;}
.index_container .navbar-nav a{color: #fff; font-style: italic; font-weight: bold; letter-spacing: 0.05em;}
.click_here_text{cursor: pointer;}
.video_player_container{background: #12332E; color: #ffffff;}

/* Video player style */
.player_wrapper{width: 100%; position: relative;}
.controls_wrapper{position: absolute; top: 0; bottom: 0; left: 0; right: 0; background: rgba(0, 0, 0, 0.1); display: flex; flex-direction: column; justify-content: space-between; z-index: 1;}
.play_background{
    display: flex;
    width: 85px;
    height: 85px;
    border-radius: 50%;
    background: #000000D9;
    opacity: 0.85;
    align-items: center;
    justify-content: center;
}
.play_icon{
    width: 30px;
    height: 30px;
    margin-left: 8px;
    cursor: pointer;
}
.play_video_controls{
    position: absolute;
    top: 40%;
    left: 48%;
}
.video_control_icon{width: 20px; height: 20px; cursor: pointer; margin: 0 15px;}
.bottom_controls{position: absolute; bottom: 10px; width: 100%;}


@media (max-width : 991px){
    .player_mobile{position: absolute; top: 0; left: 0; z-index: 2; max-height: 100%;}
    .sports_mobile{ position: absolute; bottom: 20px; z-index: 3; max-width: 300px;}
    .guy_happy_mobile_logo{display: none;}
    .sports_newest_logo{display: none;}
    .index_header .top_predictor_logo{z-index: 3; max-width: 120px;}
    .index_header .nav{padding: 20px;}

    .index_container .navbar-nav { justify-content: center !important;  padding: 25px; border-radius: 5px;box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.65); height: 35vh;}
    .navbar_button_container a{text-align: start; letter-spacing: 0.05em;}
    .navbar_button_container{padding-left: 25px; position: relative; height: 100px;}
    /* .navbar_button_container .btn_go_to_login{margin: 0; position: absolute; top: 0;}
    .navbar_button_container .btn_how_to_play{position: absolute; bottom: 0;} */
    .index_container .navbar-nav .btn_go_to_login{margin: 0;}
    .index_container .navbar-nav a{color: #ffffff !important;}
    .navbar_button_container a:hover{ color: #FFB500;}

    .sports_newest_container{
        margin-left: 15px;
        bottom: 20px;
        font-size: 45px;
    }

    .sports_newest_container .sports{
        display: inline-block;
        vertical-align: top;
        height: .75em;
        line-height: 0.75em;
        color: #D99D0B;
    }
    .sports_newest_container .newest{
        display: inline-block;
        height: .75em;
        line-height: 0.60em;
        color: #D99D0B;
        margin-left: 5px;
    }
    .sports_newest_container .interactive{
        color: #ffffff;
        padding-top: 3px;
        display: block;
    }
    .sports_newest_container .prediciton{
        color: #ffffff;
        padding-top: 8px;
        display: block;
    }
    .btn_go_to_register{display: block;}
    /* .index_header_center .btn_index_signup{display: none;}
    .index_header_center .btn_index_login{display: none;} */

    .slider{
        height: auto;
        display: block;
        margin: 0 auto;
    }
    .slide.active{
        width: 285px;
        /* height: 350px; */
    }
    .slide{
        display: block;
        margin: 0 auto;
        /* margin-bottom: 20px; */
    }
    .index_video_player{height: 300px;}
    .index_video_container{padding: 0;}

}

@media (max-width : 576px){
    .index_header_center{z-index: unset;}
    .index_center.account_btns{z-index: 3; align-items: center; justify-content: flex-end;margin-top: 160px;}
    .slider_container.index_slider_container{ padding-top: 150px;}
    .landing_page_container{padding-top: 150px;}
    .index-carousel{
        padding-top: 20%;
    }
}

/* desktop view */
@media (min-width : 992px){
    .index_container .index_header .navbar-nav{ z-index: 3;}
    .guy_happy_mobile_logo{max-width: 100%; max-height: 100%; position: absolute; top: 0px; left: 95px;  margin-right: auto; z-index: 2;}
    .sports_newest_logo{max-width: 485px; position: absolute; margin-left: 195px;  margin-right: auto; bottom: 35px; z-index: 3;}
    .player_mobile{display: none;}
    .sports_mobile{display: none;}
    .index_header .top_predictor_logo {max-width: 262px;}
    .sports_newest_container{
        margin-left: 195px;
        font-size: 60px;
        bottom: 40px;
    }

    .sports_newest_container .sports{
        display: inline-block;
        vertical-align: top;
        height: .75em;
        line-height: 0.75em;
        color: #D99D0B;
    }
    .sports_newest_container .newest{
        height: .75em;
        line-height: 0.6em;
        padding-top: 10px;
        display: block;
        color: #D99D0B;
    }
    .sports_newest_container .interactive{
        color: #ffffff;
        padding-top: 13px;
        display: block;
    }
    .sports_newest_container .prediciton{
        color: #ffffff;
        padding-top: 8px;
        display: block;
    }
    .btn_go_to_register{display: none;}
    .index_header_center .btn_index_signup{display: block;}
    .index_header_center .btn_index_login{display: block;}
    .index_video_player{height: 600px;}
    .index_video_container{padding: 0 55px;}
}

.logo_icon{ z-index: 3;}
.account_btns{display: flex; flex-direction: column; align-items: flex-end; justify-content: center; padding: 0 55px;}
.index_video_container div:first-child{height: 100% !important;}
.text-block {position: absolute; bottom: 0px; background-color: black; color: #000000; width: 100%; height: 55%; border-radius: 5px; background: #FFB500; padding: 10px;}
.landing_title_text{font-size: 30px; margin: 0; text-align: center; font-weight: 700}
.landing_offer_text{font-size: 24px; margin: 0; text-align: center; font-weight: 700}
.landing_footer_text{font-size: 16px; margin: 0; text-align: center; font-weight: 700}