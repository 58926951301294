.safer_gambling_container{width: 100%;  background: #F3F3F3; color: #12332E; padding: 20px 0;}
.safer_gambling_header{text-align: center; color: #12332E; font-weight: bold; font-style: italic; font-family: 'Roboto';}
.safer_gambling_btns{ margin-top: 100px; display: flex; justify-content: space-between;}
.btn_safer_gambling span{transform: skew(20deg);}
.title_bold{color: #12332E; font-weight: bold;}
.border_div{ margin-top: 45px; width: 100%; height: 3px; background: #12332E;}
.active_btn{ display: block; width: 3px; height: 45px; position: absolute;  margin-top: 40px; background: #12332E;}
/* .btn_container{width: 20%;} */
.btn_container button{width: 170px; height: 40px;}
.btn_container button span{font-weight: 600;}
.gambling_center{max-width: 630px; margin: auto;display: flex;justify-content: center; flex-direction: column;}
.gambling_center label{font-size: 16px; margin-right: 15px; width: 120px; text-align: end;}
.gambling_center select{height: 45px; background: #FFFFFF; border: 1px solid #12332E; box-sizing: border-box; width: 100%; border-radius: 6px; color: #6F6F6F; outline: none; padding: 0 15px;}
.gambling_center input{height: 45px; background: #FFFFFF; border: 1px solid #12332E; box-sizing: border-box; width: 100%; border-radius: 6px; color: #6F6F6F; outline: none; padding: 0 15px;}
.safer_gambling_container .safer_title{margin-top: 25px;}
.gambling_btns{text-align: end;}
.gambling_btns button{margin: 0 10px;}
.gambling_select{display: flex; align-items: center;}
.gambling_deactivate_account{display: flex; justify-content: flex-end;}
.modal-header button{border: none; background: none;}
.modal-footer button{border: none; background: none; text-decoration: underline; text-transform: uppercase; color: #12332E; font-family: 'Roboto';}
.safer_gambling_container select:disabled{cursor: not-allowed;}
@media (max-width: 991px) {
    .safer_gambling_btns{display: block; padding: 0 25px;}
    .safer_gambling_container{padding: 25px;}
    .btn_container{margin-bottom: 15px; display: flex !important; justify-content: center;}
    .active_btn{display: none;}
    .gambling_center label{display: block; text-align: start;}
    .gambling_btns{text-align: center;}
    .gambling_btns button{margin: 10px 0;}
    .gambling_select{display: block;}
    .gambling_deactivate_account{display: flex; justify-content: center;}
}