.account_container {
  width: auto;
  height: auto;
  left: 0px;
  background: #f3f3f3;
  font-family: "Roboto Condensed";
}
.center_account {
  max-width: 950px;
  margin: auto;
  padding: 45px 40px;
}
.deposit_container {
  margin-top: 45px;
}
label {
  color: #12332e;
  font-weight: bold;
  font-size: 22px;
}
.account_input {
  background: #ffffff;
  border: 1px solid #12332e;
  box-sizing: border-box;
  width: 100%;
  border-radius: 6px;
  height: 45px;
  padding: 0 14px;
  outline: none;
  padding: 0 12px;
  font-size: 14px;
}
.lbl_my_profile{text-transform: uppercase;}
/* .account_container .mt-row{margin-top: 65px;} */
.deposit_btn_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 25px;
}
.deposit_terms {
  display: block;
  color: #12332e;
  opacity: 0.48;
  font-weight: 300;
}
.trade_mark{margin-top: 35px}

.withdraw_terms p {
  color: #12332e;
  font-weight: 300;
  margin-top: 25px;
  opacity: 0.48;
}
.transactions_center {
  max-width: 1250px;
  margin: 0 auto;
  padding: 10px 20px;
}
.account_transactions {
  margin-top: 25px;
  margin-bottom: 25px;
}
.account_transactions .tbl_account_transactions thead {
  color: #12332e;
  font-size: 20px;
  font-weight: bold;
  background: #ffffff;
  border-radius: 6px;
}
.account_transactions .tbl_account_transactions tbody {
  background: #ffffff;
  border-radius: 6px;
  color: #12332E;
  font-weight: 300;
}
.account_transactions .tbl_account_transactions tbody:before {
  content: "\200C";
  display: block;
  background: #f3f3f3;
}
.account_input {
  color: #6f6f6f;
}
select.account_input {
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 100%;
  background-position-y: 5px;
  padding-right: 2rem;
}
.lbl_my_account {
  font-size: 22px;
  font-weight: 700;
  color: #ffffff;
}
.my_account {
  background: #12332E;
  height: 75px;
  color: #ffffff;
  display: flex;
  align-items: center;
  padding: 0 45px;
}
.username {
  font-size: 12px;
  font-weight: 400;
  display: block;
}
.my_account .lbl_my_profile {
  font-size: 22px;
  font-weight: 700;
  color: #ffffff;
}
.hello_user {
  font-size: 22px;
  font-weight: 700;
  font-style: italic;
}
.my_account .my_profile_center {
  max-width: 1200px;
  margin: 0 auto;
  padding: 10px 20px;
}
.transaction_row {
  background: #ffffff;
  margin-bottom: 0;
  height: auto;
  padding: 8px 0;
  display: flex;
  align-items: center;
}
.transaction_row:first-child {
  color: #12332e;
  font-size: 20px;
  font-weight: bold;
}

.transaction_value {
  display: block;
}
.transaction.row {
  background: #ffffff;
  margin-bottom: 0;
  height: auto;
  padding: 12px 0;
  display: flex;
  align-items: center;
  color: #12332e;
  font-family: "Roboto Condensed";
}
.transaction.row .col {
  margin: 0 10px;
}
.center_account .row {
  font-family: "Roboto Condensed";
}
.transaction.titles {
  font-weight: 700;
}
.disabled_button {
  opacity: 0.6;
  cursor: not-allowed;
}
.btn_withdraw {
  width: 210px !important;
}
.btn_withdraw_height {
  height: 50px !important;
}
#div_holder_hexapay_payment {
  display: none;
  z-index: 9999;
  position: fixed;
  top: 0;
  text-align: center;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
}
#iframe_hexapay_payment_iframe {
  width: 100%;
  height: 95%;
  border: 0;
}

@media (max-width: 991px) {
  .deposit_btn_container {
    display: block;
    text-align: center;
  }
  .deposit_btn_container button {
    margin-top: 15px;
  }
  .account_btn_container {
    text-align: center;
  }
  .account_btn_container button {
    margin-bottom: 15px;
  }
  .withdraw_btn_container {
    display: flex;
    justify-content: center;
  }
  .deposit_container .mt-row {
    margin-bottom: 25px;
  }
  .my_account {
    position: relative;
    height: 120px;
    padding: 15px;
  }
  .my_account .lbl_my_account {
    position: absolute;
    bottom: 10px;
  }
  .my_account .user_profile_info {
    position: absolute;
    top: 10px;
  }
  .transaction_row {
    margin: 0;
    margin-bottom: 15px;
    border-radius: 5px;
  }
  .transaction_row div {
    margin: 10px 0;
  }
  .row.transaction {
    display: flex;
    flex-direction: column;
    margin-bottom: 25px !important;
    border-radius: 6px;
  }
  .transaction.titles {
    display: none;
  }
  .row.transaction .col {
    margin: 10px 0;
  }
  .row.transaction .transaction_title {
    font-weight: 900;
    word-break: break-word;
  }
  .row.transaction .transaction_value {
    word-break: break-word;
    font-size: 14px;
    padding-left: 15px;
  }
  .transactions_competition_id {
    width: 100%;
  }
  .transactions_payment_type {
    width: 100%;
  }
  .transactions_type {
    width: 100%;
  }
  .transactions_amount {
    width: 100%;
  }
  #select_payment_for{margin-bottom: 10px;}
  .transactions_payment_type, .transactions_type, .transactions_amount{margin: 10px 0;}
}
@media (min-width: 992px) {
  .account_btn_container {
    display: flex;
    justify-content: space-between;
  }
  .withdraw_btn_container {
    display: flex;
    justify-content: flex-end;
  }
  .transaction_row {
    margin: 0;
    border-radius: 0;
  }
  .transaction_title {
    display: none;
  }
  .transaction.titles {
    margin: 0 0 25px 0;
  }
  .row.transaction.titles {
    border-radius: 6px;
  }
  .row.transaction:not(.titles) {
    border-radius: 0;
  }
  .row.transaction {
    border-radius: 6px 6px 0 0;
  }
  /* .row.transaction:not(.titles):first-child{border-radius: 6px 6px 0 0 ;} */
  /* .row.transaction:not(.titles):last-child{border-radius: 0 0 6px 6px;} */
  .transactions_competition_id {
    width: 135px;
  }
  .transactions_payment_type {
    width: 125px;
  }
  .transactions_type {
    width: 80px;
  }
  .transactions_amount {
    width: 100px;
  }
  .transactions_amount{text-align: right;}
  .transaction_datetime{max-width: 110px;}
}
